body {
  background-color: #F5F2E6 !important;
  font-family: 'Book';
}

#ck {
  position: fixed;
  top: 0;
  width: 100%;
  display: none;
}

#ck img {
  max-width: 100%;
}

.displayBlockCK {
  display: block !important;
  opacity: 0.5;
}

.App {
  text-align: center;
}

div.ce {
  text-align: center;
}

.male12pr p {
  margin-left: 12%;
}

.mari12pr p {
  margin-right: 12%;
}

div.le {
  text-align: left;
}

.lh25 {
  line-height: 25px;
}

.lh50 {
  line-height: 50px;
}

.marginUp50pr {
  margin-top: -90px;
}

.marginDown50pr {
  margin-top: 210px;
}

img.cpfp {
  max-width: 100%;
  height: auto;
}

div.maLe125 {
  margin-left: 125px;
}

.bigFont {
  font-size: 52px;
}

.bigFont2 {
  font-size: 34px;
}

#textCwrapper {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

img.imgTo80pr {
  width: 80%;
  height: 450px;
}
video.placeholderVideo {
  max-width: 800px;
  width: 100%;
  height: auto;
}

@keyframes fadeIt {
  0%   { background-color: #7A821F; }
  50%  { background-color: #D5D919; }
  100% { background-color: #7A821F; }
}

.colorBox {
  width: 100%;
  height: auto;
  border-radius: 0px 50px 0px 0px;
  background-color: #7A821F;
  animation: fadeIt 10s ease-in-out;
  animation-iteration-count: infinite;
  line-height: 0px;
}

.colorBoxMod {
  border-radius: 0px 0px 50px 0px;
  height: auto;
}

.floatRight {
  float: right;
}

.whiteBox {
  width: 100%;
  height: auto;
  border-radius: 50px 0px 0px 0px;
  background-color: #FFFFFF;
}

.noRightPad {
  margin-right: 0px !important;
  padding-right: 0px !important;
}

.noLeftPad {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.spacerDesktop70px {
  width: 100%;
  height: 70px;
}

.spacerDesktop100px {
  width: 100%;
  height: 100px;
}

.spacerDesktop150px {
  width: 100%;
  height: 150px;
}

.font20px {
  font-size: 20px;
}

.bookFont {
  font-family: 'Book' !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#claudiaLogo {
  max-height: 800px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
